import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import { layout, container, contact, left, right, content, header, hasHeader, thanks, headerContent } from './akcja-cytrynowka-layout.module.scss';
import { grid } from '../styles/grid.module.scss';
import staticFiles from "../config/static-files";

import MainLayout, {IMainLayoutProps} from "./main-layout";
import ContestContact from "../components/organisms/contest-contact";
import ContestThankYou, {IContestThankYouProps} from "../components/organisms/contest-thank-you";

const leftImg = '../assets/images/akcja-cytrynowka/akcja-cytrynowka-left.png';
const rightImg = '../assets/images/akcja-cytrynowka/akcja-cytrynowka-right.png';

interface IAkcjaCytrynowkaLayout extends IMainLayoutProps {
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
}

const AKCJA_CYTRYNOWKA_START_TIMESTAMP = 1716155999000;
const ENVIRONMENT = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const AkcjaCytrynowkaLayout: React.FC<IAkcjaCytrynowkaLayout> = ({ children, className = '', headerProps, ...rest }) => {

    useEffect(() => {
        if (AKCJA_CYTRYNOWKA_START_TIMESTAMP > new Date().getTime() && ENVIRONMENT === 'production') {
            navigate('/', { replace: true });
        }
    }, []);

    return (
        <MainLayout {...rest} className={`${layout} ${headerProps ? hasHeader : ''}`}>
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    <div className={left}>
                        <StaticImage
                            src={leftImg}
                            alt=""
                        />
                    </div>
                    <div className={right}>
                        <StaticImage
                            src={rightImg}
                            alt=""
                        />
                    </div>
                    {headerProps && <ContestThankYou {...headerProps} className={thanks} />}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                <div className={`${className} ${content}`}>
                    <div className={`${left} left`}>
                        <StaticImage
                            src={leftImg}
                            alt=""
                        />
                    </div>
                    <div className={`${right} right`}>
                        <StaticImage
                            src={rightImg}
                            alt=""
                        />
                    </div>
                    { children }
                </div>
                <ContestContact className={contact} regulationsUrl={staticFiles.akcjaCytrynowkaRegulations} />
            </div>
        </MainLayout>
    )
}

export default AkcjaCytrynowkaLayout;