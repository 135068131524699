// extracted by mini-css-extract-plugin
export var contact = "akcja-cytrynowka-layout-module--contact--d6c7d";
export var container = "akcja-cytrynowka-layout-module--container--744aa";
export var content = "akcja-cytrynowka-layout-module--content--d00bf";
export var errorBlink = "akcja-cytrynowka-layout-module--error-blink--f7506";
export var hasHeader = "akcja-cytrynowka-layout-module--has-header--5e20d";
export var header = "akcja-cytrynowka-layout-module--header--f04bc";
export var headerContent = "akcja-cytrynowka-layout-module--header-content--b0c11";
export var layout = "akcja-cytrynowka-layout-module--layout--fda10";
export var left = "akcja-cytrynowka-layout-module--left--0442b";
export var right = "akcja-cytrynowka-layout-module--right--a4589";
export var thanks = "akcja-cytrynowka-layout-module--thanks--94594";