import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IAkcjaCytrynowkaValues } from "../formik/akcja-cytrynowka-form.config";
import { IAkcjaCytrynowkaWinnerValues } from "../formik/akcja-cytrynowka-form-winner.config";

export const akcjaCytrynowkaFormMutation = (data: IAkcjaCytrynowkaValues) => {
    return axios.post(prependApiUrl('/akcja-cytrynowka/create'), {
        ...data,
    });
};

export const postAkcjaCytrynowkaWinner = (createForm: IAkcjaCytrynowkaWinnerValues) => {
    return axios.post(prependApiUrl('/akcja-cytrynowka/addresses'), {
        ...createForm,
    });
};

export const getAkcjaCytrynowkaCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/akcja-cytrynowka/check/${token}`), {});
};
