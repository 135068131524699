// extracted by mini-css-extract-plugin
export var button = "akcja-cytrynowka-winner-form-module--button--b79a5";
export var container = "akcja-cytrynowka-winner-form-module--container--bd29e";
export var error = "akcja-cytrynowka-winner-form-module--error--f9d6f";
export var errorBlink = "akcja-cytrynowka-winner-form-module--error-blink--e2582";
export var fields = "akcja-cytrynowka-winner-form-module--fields--77781";
export var full = "akcja-cytrynowka-winner-form-module--full--91e90";
export var header = "akcja-cytrynowka-winner-form-module--header--b28e2";
export var icon = "akcja-cytrynowka-winner-form-module--icon--f0fa7";
export var info = "akcja-cytrynowka-winner-form-module--info--3be44";
export var input = "akcja-cytrynowka-winner-form-module--input--2d6a0";
export var loading = "akcja-cytrynowka-winner-form-module--loading--04f72";
export var success = "akcja-cytrynowka-winner-form-module--success--52f07";
export var title = "akcja-cytrynowka-winner-form-module--title--5f822";